@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;
@use "sass:math";

/*
page
*/

body:not(#index) {
  .page-header {

    .jumbotron {
      background-color: transparent;
      background: url(#{mixin.set_image_path('page/page_header_img.jpg')}) no-repeat center center;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 0;
      margin-bottom: 20px;
      min-height: 450px;
      position: relative;
      overflow: hidden;

      @include mixin.tabletP {
        min-height: 300px;
      }

      &::before {
        background-color: rgba(255, 255, 255, .6);
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .modTitle {
      margin-bottom: 0;
      padding: 0 15px;
      position: relative;
      z-index: 1;

      &__en {
        color: variables.$COLOR_TEXT;
        margin-bottom: 10px;
      }

    }
  }

  .page-sec-inner {
    padding: 60px 0 70px;

    @include mixin.tabletP {
      padding: 40px 0 50px;
    }

    .container-fluid {
      position: relative;
    }
  }
}

.mainContainer {
  position: relative;
}

.myBlockAnime {
  transform: translate(0, 0);
  z-index: -1;

  &.-left1,
  &.-left2 {
    left: -15%;

    @include mixin.tabletP {
      left: -10%;
    }
  }

  &.-left1 {
    top: 3%;
  }

  &.-left2 {
    top: 75%;
  }

  &.-right1,
  &.-right2 {
    left: auto;
    right: -10%;

    @include mixin.tabletP {
      right: -15%;
    }
  }

  &.-right1 {
    top: 50%;
    transform: translateY(-50%);
  }

  &.-right2 {
    top: auto;
    bottom: 3%;

    @include mixin.tabletP {
      bottom: 120px;
    }
  }
}

.modPageContainer {
  position: relative;

  .bgAnimeBlock {
    .myBlockAnime {

      &.-left1,
      &.-left2 {
        left: 0;

        @include mixin.tabletP {
          left: -20%;
        }
      }

      &.-right1,
      &.-right2 {
        left: auto;
        right: 0;

        @include mixin.tabletP {
          right: -20%;
        }
      }
    }
  }
}

#myBlockMimasaka {

  .sec1 {

    .sec-inner {
      padding: 60px 0 50px;

      @include mixin.tabletP {
        padding: 40px 0 50px;
      }
    }

    .modImgBlock {
      margin-bottom: 50px;
    }

    .myBlockSolution {
      padding: 60px 0 50px;
      position: relative;

      @include mixin.tabletP {
        padding: 20px 0 0;
      }

      .modTitle {
        margin-bottom: 20px;
      }

      .modFlexBlock {
        gap: 10px 0;
        flex-direction: column;
        margin: 0 auto;
        width: 640px;
        max-width: 100%;

        li {
          background-color: white;
          border-radius: 36px;
          font-size: calc(14px + 10 * (100vw - 320px) / 1600);
          text-align: center;
          padding: 14px 5px;

          span {
            color: #C1272D;
            display: inline;
          }
        }
      }

      .modImgBlock {
        margin-bottom: 0;
        position: absolute;
        bottom: 0;
        left: 0;

        @include mixin.tabletP {
          position: static;
          bottom: auto;
          left: auto;
        }
      }
    }


  }

  .sec2 {
    .sec-inner {
      padding: 50px 0;
    }

    .modTitle {
      margin-bottom: 20px;

      &__jp {

        &.-dashed {
          display: inline-block;
          color: variables.$COLOR_MAIN;
          font-size: calc(24px + 12 * (100vw - 320px) / 1600);
          font-weight: 700;
          padding-left: 0;

          span {
            border-bottom: 2px variables.$COLOR_MAIN dashed;
            display: inline;
            line-height: 2;
          }

          &::before {
            display: none;
          }
        }
      }
    }

    .modBlockBnr {
      background-color: #CDDDF5;
      border-radius: 16px;
      padding: 40px 24px;
      margin-bottom: 20px;

      @include mixin.tabletP {
        padding: 20px;
      }

      &__ttl {
        font-size: calc(18px + 8 * (100vw - 320px) / 1600);
        font-weight: 500;
        line-height: normal;
        text-align: center;
        margin-bottom: 20px;

        @include mixin.tabletP {
          text-align: left;
        }
      }

      .modFlexBlock {
        gap: 0 20px;
        justify-content: center;

        @include mixin.tabletP {
          gap: 10px 0;
        }

        li {
          text-align: center;
          width: calc(100% / 3  - 14px);

          @include mixin.tabletP {
            width: 100%;
          }
        }
      }
    }

    .modCardBlock {

      .modCard {
        width: calc(100% / 2);

        @include mixin.tabletP {
          width: 100%;
        }
      }
    }
  }

  .sec3 {

    .sec-inner {
      padding: 50px 0 120px;

      @include mixin.tabletP {
        padding: 50px 0 70px;
      }
    }

    .modTitle {
      margin-bottom: 20px;
    }

    .modCardBlock {
      flex-wrap: wrap;
      gap: 30px 20px;
      margin-bottom: 50px;

      @include mixin.tabletP {
        gap: 30px 20px;
      }

      .modCard {
        width: calc(100% / 3 - 20px);

        @include mixin.tabletP {
          width: calc(100% / 2 - 10px);
        }

        .card-img {
          position: relative;

          &::after {
            background: url(#{mixin.set_common_path('icon_zoom.svg')}) no-repeat center center;
            background-size: 100% auto;
            content: "";
            display: block;
            width: 22px;
            height: 22px;
            position: absolute;
            bottom: 12px;
            right: 12px;
          }
        }

        .card-body {
          padding-top: 10px;
        }
      }
    }

    .modBtnBlock {
      margin-bottom: 50px;
    }

    .modContents {
      .modCard {
        background-color: white;
        border-radius: 16px;
        padding: 30px 30px 20px;
        width: 100%;
        max-width: 100%;

        @include mixin.tabletP {
          padding: 20px 15px;
        }

        +.modCard {
          margin-top: 15px;
        }

        &:last-of-type {
          margin-bottom: 50px;
        }

        .card-title {
          font-size: calc(18px + 6 * (100vw - 320px) / 1600);
          font-weight: 700;
          line-height: 1;
          margin-bottom: 10px;
          padding-left: 22px;
          position: relative;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }

          &::before {
            background: url("#{mixin.set_common_path('icon_ttl_ja.svg')}") no-repeat center center;
            content: "";
            display: block;
            width: 17px;
            height: 17px;
            left: 0;
          }
        }

        ul {
          display: flex;
          gap: 0 120px;

          @include mixin.tabletP {
            flex-direction: column;
            gap: 0;
          }
        }
      }
    }
  }

  .modCardBlock {
    display: flex;
    gap: 0 30px;

    @include mixin.tabletP {
      flex-wrap: wrap;
      gap: 40px 0;
    }

    .modCard {

      .card-body {
        padding-top: 20px;
      }

      .card-title {
        font-size: calc(16px + 8 * (100vw - 320px) / 1600);
        font-weight: 700;
        line-height: normal;
      }

      .card-text {
        line-height: normal;
      }
    }
  }

  .modal-header {

    .modal-closed {
      color: variables.$COLOR_TEXT;
      font-weight: 700;
      text-decoration: none;
    }
  }

  .modal-body {
    img {
      width: 100%;
    }
  }
}
