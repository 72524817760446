@charset "UTF-8";
/* 余白 8の倍数 */
/*
page
*/
body:not(#index) .page-header .jumbotron {
  background-color: transparent;
  background: url(../img/page/page_header_img.jpg) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  margin-bottom: 20px;
  min-height: 450px;
  position: relative;
  overflow: hidden;
}
@media not all and (min-width: 768px) {
  body:not(#index) .page-header .jumbotron {
    min-height: 300px;
  }
}
body:not(#index) .page-header .jumbotron::before {
  background-color: rgba(255, 255, 255, 0.6);
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
body:not(#index) .page-header .modTitle {
  margin-bottom: 0;
  padding: 0 15px;
  position: relative;
  z-index: 1;
}
body:not(#index) .page-header .modTitle__en {
  color: #121213;
  margin-bottom: 10px;
}
body:not(#index) .page-sec-inner {
  padding: 60px 0 70px;
}
@media not all and (min-width: 768px) {
  body:not(#index) .page-sec-inner {
    padding: 40px 0 50px;
  }
}
body:not(#index) .page-sec-inner .container-fluid {
  position: relative;
}

.mainContainer {
  position: relative;
}

.myBlockAnime {
  transform: translate(0, 0);
  z-index: -1;
}
.myBlockAnime.-left1, .myBlockAnime.-left2 {
  left: -15%;
}
@media not all and (min-width: 768px) {
  .myBlockAnime.-left1, .myBlockAnime.-left2 {
    left: -10%;
  }
}
.myBlockAnime.-left1 {
  top: 3%;
}
.myBlockAnime.-left2 {
  top: 75%;
}
.myBlockAnime.-right1, .myBlockAnime.-right2 {
  left: auto;
  right: -10%;
}
@media not all and (min-width: 768px) {
  .myBlockAnime.-right1, .myBlockAnime.-right2 {
    right: -15%;
  }
}
.myBlockAnime.-right1 {
  top: 50%;
  transform: translateY(-50%);
}
.myBlockAnime.-right2 {
  top: auto;
  bottom: 3%;
}
@media not all and (min-width: 768px) {
  .myBlockAnime.-right2 {
    bottom: 120px;
  }
}

.modPageContainer {
  position: relative;
}
.modPageContainer .bgAnimeBlock .myBlockAnime.-left1, .modPageContainer .bgAnimeBlock .myBlockAnime.-left2 {
  left: 0;
}
@media not all and (min-width: 768px) {
  .modPageContainer .bgAnimeBlock .myBlockAnime.-left1, .modPageContainer .bgAnimeBlock .myBlockAnime.-left2 {
    left: -20%;
  }
}
.modPageContainer .bgAnimeBlock .myBlockAnime.-right1, .modPageContainer .bgAnimeBlock .myBlockAnime.-right2 {
  left: auto;
  right: 0;
}
@media not all and (min-width: 768px) {
  .modPageContainer .bgAnimeBlock .myBlockAnime.-right1, .modPageContainer .bgAnimeBlock .myBlockAnime.-right2 {
    right: -20%;
  }
}

#myBlockMimasaka .sec1 .sec-inner {
  padding: 60px 0 50px;
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .sec1 .sec-inner {
    padding: 40px 0 50px;
  }
}
#myBlockMimasaka .sec1 .modImgBlock {
  margin-bottom: 50px;
}
#myBlockMimasaka .sec1 .myBlockSolution {
  padding: 60px 0 50px;
  position: relative;
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .sec1 .myBlockSolution {
    padding: 20px 0 0;
  }
}
#myBlockMimasaka .sec1 .myBlockSolution .modTitle {
  margin-bottom: 20px;
}
#myBlockMimasaka .sec1 .myBlockSolution .modFlexBlock {
  gap: 10px 0;
  flex-direction: column;
  margin: 0 auto;
  width: 640px;
  max-width: 100%;
}
#myBlockMimasaka .sec1 .myBlockSolution .modFlexBlock li {
  background-color: white;
  border-radius: 36px;
  font-size: calc(14px + 10 * (100vw - 320px) / 1600);
  text-align: center;
  padding: 14px 5px;
}
#myBlockMimasaka .sec1 .myBlockSolution .modFlexBlock li span {
  color: #C1272D;
  display: inline;
}
#myBlockMimasaka .sec1 .myBlockSolution .modImgBlock {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .sec1 .myBlockSolution .modImgBlock {
    position: static;
    bottom: auto;
    left: auto;
  }
}
#myBlockMimasaka .sec2 .sec-inner {
  padding: 50px 0;
}
#myBlockMimasaka .sec2 .modTitle {
  margin-bottom: 20px;
}
#myBlockMimasaka .sec2 .modTitle__jp.-dashed {
  display: inline-block;
  color: #0556cc;
  font-size: calc(24px + 12 * (100vw - 320px) / 1600);
  font-weight: 700;
  padding-left: 0;
}
#myBlockMimasaka .sec2 .modTitle__jp.-dashed span {
  border-bottom: 2px #0556cc dashed;
  display: inline;
  line-height: 2;
}
#myBlockMimasaka .sec2 .modTitle__jp.-dashed::before {
  display: none;
}
#myBlockMimasaka .sec2 .modBlockBnr {
  background-color: #CDDDF5;
  border-radius: 16px;
  padding: 40px 24px;
  margin-bottom: 20px;
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .sec2 .modBlockBnr {
    padding: 20px;
  }
}
#myBlockMimasaka .sec2 .modBlockBnr__ttl {
  font-size: calc(18px + 8 * (100vw - 320px) / 1600);
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .sec2 .modBlockBnr__ttl {
    text-align: left;
  }
}
#myBlockMimasaka .sec2 .modBlockBnr .modFlexBlock {
  gap: 0 20px;
  justify-content: center;
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .sec2 .modBlockBnr .modFlexBlock {
    gap: 10px 0;
  }
}
#myBlockMimasaka .sec2 .modBlockBnr .modFlexBlock li {
  text-align: center;
  width: calc(33.3333333333% - 14px);
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .sec2 .modBlockBnr .modFlexBlock li {
    width: 100%;
  }
}
#myBlockMimasaka .sec2 .modCardBlock .modCard {
  width: 50%;
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .sec2 .modCardBlock .modCard {
    width: 100%;
  }
}
#myBlockMimasaka .sec3 .sec-inner {
  padding: 50px 0 120px;
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .sec3 .sec-inner {
    padding: 50px 0 70px;
  }
}
#myBlockMimasaka .sec3 .modTitle {
  margin-bottom: 20px;
}
#myBlockMimasaka .sec3 .modCardBlock {
  flex-wrap: wrap;
  gap: 30px 20px;
  margin-bottom: 50px;
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .sec3 .modCardBlock {
    gap: 30px 20px;
  }
}
#myBlockMimasaka .sec3 .modCardBlock .modCard {
  width: calc(33.3333333333% - 20px);
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .sec3 .modCardBlock .modCard {
    width: calc(50% - 10px);
  }
}
#myBlockMimasaka .sec3 .modCardBlock .modCard .card-img {
  position: relative;
}
#myBlockMimasaka .sec3 .modCardBlock .modCard .card-img::after {
  background: url(../img/common/icon_zoom.svg) no-repeat center center;
  background-size: 100% auto;
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: 12px;
  right: 12px;
}
#myBlockMimasaka .sec3 .modCardBlock .modCard .card-body {
  padding-top: 10px;
}
#myBlockMimasaka .sec3 .modBtnBlock {
  margin-bottom: 50px;
}
#myBlockMimasaka .sec3 .modContents .modCard {
  background-color: white;
  border-radius: 16px;
  padding: 30px 30px 20px;
  width: 100%;
  max-width: 100%;
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .sec3 .modContents .modCard {
    padding: 20px 15px;
  }
}
#myBlockMimasaka .sec3 .modContents .modCard + .modCard {
  margin-top: 15px;
}
#myBlockMimasaka .sec3 .modContents .modCard:last-of-type {
  margin-bottom: 50px;
}
#myBlockMimasaka .sec3 .modContents .modCard .card-title {
  font-size: calc(18px + 6 * (100vw - 320px) / 1600);
  font-weight: 700;
  line-height: 1;
  margin-bottom: 10px;
  padding-left: 22px;
  position: relative;
}
#myBlockMimasaka .sec3 .modContents .modCard .card-title::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#myBlockMimasaka .sec3 .modContents .modCard .card-title::before {
  background: url("../img/common/icon_ttl_ja.svg") no-repeat center center;
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  left: 0;
}
#myBlockMimasaka .sec3 .modContents .modCard ul {
  display: flex;
  gap: 0 120px;
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .sec3 .modContents .modCard ul {
    flex-direction: column;
    gap: 0;
  }
}
#myBlockMimasaka .modCardBlock {
  display: flex;
  gap: 0 30px;
}
@media not all and (min-width: 768px) {
  #myBlockMimasaka .modCardBlock {
    flex-wrap: wrap;
    gap: 40px 0;
  }
}
#myBlockMimasaka .modCardBlock .modCard .card-body {
  padding-top: 20px;
}
#myBlockMimasaka .modCardBlock .modCard .card-title {
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
  font-weight: 700;
  line-height: normal;
}
#myBlockMimasaka .modCardBlock .modCard .card-text {
  line-height: normal;
}
#myBlockMimasaka .modal-header .modal-closed {
  color: #121213;
  font-weight: 700;
  text-decoration: none;
}
#myBlockMimasaka .modal-body img {
  width: 100%;
}